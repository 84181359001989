import React, { useEffect, useState } from "react";
import "../styles/About.css";
import Modal from "./Modal";
import axios from "axios";
import Lottie from "lottie-react";
import donate from "./../assets/RP2y3LxJAa.json";
import ModelDonet from "./ModelDonet";
import parse from "html-react-parser";
import { Link } from "react-router-dom";

const About = () => {
  const [showModal, setShowModal] = useState(false);
  const [NewData, setNewData] = useState([]);
  const [donet, setdonet] = useState(false);

  const GetAboutus = async () => {
    try {
      const getdata = await axios.get(
        `https://thekitu.com/api/admin/getAboutus`
      );
      if (getdata.status === 200) {
        setNewData(getdata.data.news);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetAboutus();
  }, []);

  return (
    <div>
      <div id="about">
        <div className="about">
          <h1 style={{ borderBottom: "3px solid white" }}>
            About KITU
          </h1>


          <p>{NewData[0]?.aboutkittu}</p>
        </div>
      </div>


      {NewData?.map((item, i) => {
        return (
          <div key={i}>
            {i % 2 === 1 ? (
              <div className="container">
                <section id="sec1">
                  <div className="row">
                    <div className="col-md-6">
                      <div>
                        <img
                          src={`https://thekitu.com/Aboutus/${item?.image}`}
                          alt="joinnow images"
                          style={{ height: "550px", width: "90%", objectFit: "cover", objectPosition: "center" }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <h3>{item?.title}</h3>
                      <p  >
                        <td  >{parse(`<div>${item?.description}</div>`)}</td>
                      </p>
                    </div>
                  </div>
                </section>
              </div>
            ) : (
              <>
                <section id="sec2">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-6 ">
                        <div className="why-join">
                          <h3>Why Join KITU?</h3>
                          <p style={{ textAlign: "justify" }}>
                            {item?.whyjoinkittu}
                          </p>

                          <div className="nav-button">
                            <Modal
                              show={showModal}
                              onClose={() => setShowModal(false)}
                            />
                            <ModelDonet
                              show={donet}
                              onClose={() => setdonet(false)}
                            />

                            <Link to='/joinus' className="pt-5"> <button className="joinus-btn"
                              style={{
                                height: "40px",
                                width: "100px",
                                background: "rgb(255, 255, 255)",
                                color: "rgb(217 8 18)",
                                borderRadius: " 5px",
                                margin: "15px",
                                fontWeight: "bold",
                                border: "2px solid #FF0202 ",

                              }}
                            >Join Us</button></Link>

                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div>
                          <img
                            src={`https://thekitu.com/Aboutus/${item?.whyjoinimage}`}
                            alt="joinnow images"
                            style={{ height: "auto", width: "95%", objectFit: "cover", objectPosition: "center" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section id="sec2" className="mb-2">
                  <div className="container">
                    <div className="row" >
                      <div className="col-md-6">
                        <div>
                          <img
                            src={`https://thekitu.com/Aboutus/${item?.whydonateimage}`}
                            alt="joinnow images"
                            style={{ height: "auto", width: "95%", objectFit: "cover", objectPosition: "center" }}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 ">
                        <div className="why-join">
                          <h3>Why Donate KITU?</h3>
                          <p style={{ textAlign: "justify" }}>
                            {item?.whydonatekittu}
                          </p>

                          <div className="nav-button">
                            <Modal
                              show={showModal}
                              onClose={() => setShowModal(false)}
                            />
                            <ModelDonet
                              show={donet}
                              onClose={() => setdonet(false)}
                            />


                            <Link to='/donateus'>
                              <button style={{
                                height: "40px",
                                width: "100px",
                                background: "rgb(255, 255, 255)",
                                color: "rgb(217 8 18)",
                                border: "2px solid #FF0202 ",
                                borderRadius: " 5px",
                                margin: "15px",
                                fontWeight: "bold"
                              }} >
                                Donate
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </section>




              </>
            )}
          </div>
        );
      })}


    </div>
  );
};



export default About;
