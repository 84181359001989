import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import axios from "axios";

const Receipt = () => {


  const { id } = useParams();

  const [item, setItem] = useState([]);

  const getMember = async () => {
    try {
      const res = await axios.get(`https://thekitu.com/api/user/getMembershipById/${id}`)
      setItem(res.data.membership)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getMember();
  }, [])

  const receiptRef = useRef(null);


  // const downloadPDF = async () => {
  //   const element = receiptRef.current;
  //   const canvas = await html2canvas(element, { scale: 2 }); // Higher scale for better quality
  //   const imgData = canvas.toDataURL("image/png");
  //   const pdf = new jsPDF("p", "mm", "a4"); // A4 size in portrait mode

  //   const imgWidth = 210; // A4 width in mm
  //   const imgHeight = (canvas.height * imgWidth) / canvas.width; // Maintain aspect ratio

  //   pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
  //   pdf.save(`Receipt_${item?._id}.pdf`);
  // };



  const downloadPDF = async () => {
    const element = receiptRef.current;
    const button = element.querySelector(".joinus-btn"); // Select the download button

    if (button) {
      button.style.display = "none"; // Hide the button before capturing
    }

    try {
      const canvas = await html2canvas(element, { scale: 2 }); // Higher scale for better quality
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4"); // A4 size in portrait mode

      const imgWidth = 210; // A4 width in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width; // Maintain aspect ratio

      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      pdf.save(`Receipt_${item?._id}.pdf`);
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      if (button) {
        button.style.display = "block"; // Restore the button after capturing
      }
    }
  };




  const formattedDate = item?.createdAt
    ? moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss')
    : 'No date available';

  return (
    <div
      className="d-flex justify-content-center align-items-center"
      ref={receiptRef}
      style={{
        width: "100vw",
        height: "auto",
      }}
    >
      <div
        style={{
          width: "80%",
          backgroundColor: "#ffffff",
          borderRadius: "15px",
          padding: "20px 30px",
          boxShadow: "0 10px 20px rgba(0, 0, 0, 0.15)",
          borderTop: "10px solid #BA1D1D",
          border: "1px solid #BA1D1D",
          marginTop: "80px",
          marginBottom: "80px"
        }}
      >
        {/* Header */}
        <div style={{ textAlign: "center", marginBottom: "20px" }}>
          <h1 style={{ color: "#BA1D1D", fontWeight: "bold", fontSize: "24px" }}>
            Karnataka State IT/ITeS Employees Union
          </h1>
          <p style={{ fontSize: "16px", color: "#666666" }}>
            Official Receipt for Your {item?.formtype}
          </p>
        </div>

        {/* Decorative Divider */}
        <hr
          style={{
            border: "0",
            height: "2px",
            background:
              "linear-gradient(to right, #BA1D1D, transparent, #BA1D1D)",
            marginBottom: "20px",
          }}
        />

        {/* Receipt Details */}
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "15px",
            }}
          >
            <div className="d-flex flex-column">
              <p>
                <strong>Receipt No:</strong> <span>{item?.membershipId?.replace(/^MEM/, "ONL")}</span>
              </p>
              <p>
                <strong>Date:</strong> <span>{formattedDate}</span>
              </p>
            </div>
            <button
              className="joinus-btn"
              onClick={downloadPDF}
              style={{
                height: "40px",
                width: "auto",
                background: "rgb(255, 255, 255)",
                color: "#BA1D1D",
                borderRadius: " 5px",
                margin: "15px",
                fontWeight: "bold",
                border: "2px solid #BA1D1D",
                padding: "5px"
              }}
            >
              Download Invoice
            </button>
          </div>
        </div>

        {/* Member Details */}
        <div style={{ marginBottom: "20px" }}>
          <h4
            style={{
              color: "#BA1D1D",
              fontWeight: "bold",
              borderBottom: "2px solid #BA1D1D",
              paddingBottom: "5px",
              marginBottom: "10px",
            }}
          >
            Member Information
          </h4>
          <p style={{ marginBottom: "5px" }}>
            <strong>Name:</strong> {item.name}
          </p>
          <p style={{ marginBottom: "5px" }}>
            {
              item.formtype === "Membership" ?
                <> <strong>Membership ID:</strong> {item?.membershipId?.replace(/^ONL/, "MEM") || item?._id}</>
                : <></>
            }
          </p>
          <p style={{ marginBottom: "5px" }}>
            {
              item.formtype === "Membership" ?
                <>  <strong>Phone:</strong> {item?.mobileno} </>
                : <></>
            }
          </p>
          <p style={{ marginBottom: "5px" }}>
            {
              item.formtype === "Membership" ?
                <>   <strong>Email:</strong> {item?.email || "NA"} </>
                : <></>
            }
          </p>
        </div>

        {/* Payment Information */}
        <div style={{ marginBottom: "20px" }}>
          <h4
            style={{
              color: "#BA1D1D",
              fontWeight: "bold",
              borderBottom: "2px solid #BA1D1D",
              paddingBottom: "5px",
              marginBottom: "10px",
            }}
          >
            Payment Details
          </h4>
          <p style={{ marginBottom: "5px" }}>
            <strong>Payment Type:</strong> {item?.formtype}
          </p>
          <p style={{ marginBottom: "5px" }}>
            <strong>Payment ID:</strong> {item?.payid}
          </p>
          <p style={{ fontSize: "18px", color: "#333333" }}>
            <strong>Amount:</strong>
            <span style={{ color: "#BA1D1D" }}>{item?.amount}</span>
          </p>
        </div>

        {/* Footer */}
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <p style={{ fontSize: "14px", color: "#555555", fontWeight: "bold" }}>
            Thank you for your valuable contribution!
          </p>
          <p style={{ fontSize: "12px", color: "#888888" }}>
            This is a computer-generated receipt and does not require a
            signature.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Receipt;
