import React, { useEffect, useState } from "react";
import "../styles/Detailsnews.css";
import { useLocation, useParams } from "react-router-dom";
import parse from "html-react-parser";
import moment from "moment";
import axios from "axios";
const RecentNews = () => {
    const location = useLocation();
    //   const { item } = location.state || {};
    const [item, setItem] = useState([]);
    const { id } = useParams();

    const getNews = async () => {
        try {
            const res = await axios.get(`https://thekitu.com/api/admin/getnewsandevent/${id}`);
            setItem(res?.data?.news);
        } catch (error) {

        }
    };

    useEffect(() => { getNews() }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <div>

            <div className="container">
                <div className="details-news-img">
                    <div className="details-news-content">
                        <h3>{item?.title} </h3>
                        <p>{parse(`<div>${item?.description}</div>`)}</p>
                        <div className="d-flex align-items-center gap-1 justify-content-start">
                            <p className="fw-semibold" style={{ fontSize: "16px" }}>{moment(item?.createdAt)?.format("DD-MM-YYYY")} , </p>
                            <p style={{ fontSize: "16px" }} className="fw-normal"><span className="fw-normal">Author-</span><span className="fw-semibold">{item?.author}</span></p>
                        </div>
                    </div>
                    <img src={`https://thekitu.com/News/${item?.image}`} alt="img" className="mt-2" />
                    <div className="details-news-content mt-4">
                        {item?.content?.map((item1) => {
                            return <p>{parse(`<div>${item1?.description1}</div>`)}</p>;
                        })}
                        {
                            item.description2 ? (item.description2 && <p>{parse(`<div>${item?.description2}</div>`)}</p>) : ""
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RecentNews;
