import React, { useEffect, useState } from "react";
import "../styles/Detailsnews.css";
import { Link, useLocation, useParams } from "react-router-dom";
import parse from "html-react-parser";
import axios from "axios";

function CampaignDetails() {
  const [item, setItem] = useState([]);
  const { id } = useParams();

 

  const getNews = async () => {
    try {
      const res = await axios.get(`https://thekitu.com/api/admin/getCampaign/${id}`);
      setItem(res?.data?.news);
    } catch (error) {

    }
  }

  useEffect(() => { getNews() }, [])

  useEffect(() => { window.scrollTo(0, 0) }, [])
  return (
    <div>
      <div id="about">
        <div className="about">
          <h4 style={{ borderBottom: "3px solid white" }} className="mt-2">
            {item?.title}
          </h4>

          <p>
            <p>{parse(`<div>${item?.description}</div>`)}</p>
          </p>
        </div>
      </div>
      <div className="container">

        <div className="row">

          <h3 className="my-4">{item?.title} </h3>

          <div className="col-md-6 mt-4 container">
            <div className="mt-2" style={{ textAlign: "justify" }}>
              <p>
                <p>{parse(`<div>${item?.description}</div>`)}</p>
              </p>
              <Link to={item?.link1_url} className="mt-5">
                <button
                  className="joinus-btn"
                  style={{
                    height: "40px",
                    width: "100px",
                    background: "rgb(255, 255, 255)",
                    color: "rgb(217 8 18)",
                    borderRadius: " 5px",
                    margin: "15px",
                    fontWeight: "bold",
                    border: "2px solid #FF0202 ",
                  }}
                >
                  {item?.link1_title}
                </button>
              </Link>
            </div>
          </div>

          <div className="col-md-6 ">
            <div className="">
              <img
                src={`https://thekitu.com/Campaign/${item?.image}`}
                alt="images"
                style={{
                  height: "450px",
                  width: "100%",
                  objectFit: "contain",
                  objectPosition: "center",
                }}
              />
            </div>
          </div>

          <div className="col-md-6 mt-5">
            <div className="">
              <img
                src={`https://thekitu.com/Campaign/${item?.image}`}
                alt="images"
                style={{
                  height: "450px",
                  width: "100%",
                  objectFit: "cover",
                  objectPosition: "center",
                }}
              />
            </div>
          </div>

          <div className="col-md-6 mt-4">
            <div className="mt-3" style={{ textAlign: "justify" }}>
              <p>
                <p>{parse(`<div>${item?.description}</div>`)}</p>
              </p>
            </div>
            <Link
              to={item?.link2_url?.startsWith("http") ? item.link2_url : `https://${item.link2_url}`}
              className="pt-5"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button
                className="joinus-btn"
                style={{
                  height: "40px",
                  width: "100px",
                  background: "rgb(255, 255, 255)",
                  color: "rgb(217 8 18)",
                  borderRadius: " 5px",
                  margin: "15px",
                  fontWeight: "bold",
                  border: "2px solid #FF0202 ",
                }}
              >
                {item?.link2_title}
              </button>
            </Link>
          </div>

        </div>
      </div>
    </div>
  );
}

export default CampaignDetails;
