// import React, { useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import { FaCheckCircle } from "react-icons/fa";
// import "../styles/payment.css"; // External CSS for better readability
// import axios from "axios";
// import swal from "sweetalert";
// const PaymentSuccess = ({ amount = 100, transactionId = "6343265r" }) => {
//   const navigate = useNavigate();

//   const handleHomeClick = () => {
//     sessionStorage.removeItem("config");
//     sessionStorage.removeItem("id")
//     navigate("/");
//   };

// const booking=async()=>{
//   try {
//     const config=JSON.parse(sessionStorage.getItem("config"));
// if(!config) return
//     let res=await axios(config);
//     if(res.status==200){
//       swal({
//         title: "Success!",
//         text: "Order Placed Successfully",
//         icon: "success", // Available options: 'success', 'error', 'warning', 'info', 'question'
//         button: "OK",
//       });
//       sessionStorage.removeItem("config");
//       sessionStorage.removeItem("id")
//       setTimeout(()=>{
//         navigate("/");
//       },2000)

//     }
//   } catch (error) {
//     console.log(error);

//   }
// }
// useEffect(() => {
//   const id = sessionStorage.getItem("id");
//   if (id) {
//     booking();
//   }
// }, []);

//   return (
//     <div className="payment-success-container">
//       <FaCheckCircle className="payment-success-icon" />
//       <h1 className="payment-success-title">Payment Successful!</h1>
//       <p className="payment-success-message">
//         Thank you for your payment. Your transaction was successful.
//       </p>

//       <button onClick={handleHomeClick} className="payment-success-button">
//         Back to Home
//       </button>
//     </div>
//   );
// };

// export default PaymentSuccess;





import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa"; // Icons for success and failure
import axios from "axios";
import swal from "sweetalert";
import "../styles/Payment.css"

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // Extract query parameters
  const transactionId = searchParams.get("transactionId");
  const userId = searchParams.get("userID");

  // State for payment status and details
  const [paymentStatus, setPaymentStatus] = useState(null); // e.g., "CR" or "FAILED"
  const [paymentDetails, setPaymentDetails] = useState(null); // Store the full payment data

  const handleHomeClick = () => {
    navigate("/orders?userID=" + userId);
  };



  const checkPaymentStatus = async () => {
    try {
      // Construct API endpoint
      const url = `https://thekitu.com/api/user/checkPayment/${transactionId}`;

      // Make the GET request
      const response = await axios.get(url);

      if (response.status == 200) {
        const paymentData = response.data.success;
        setPaymentDetails(paymentData);
        if (paymentData.status === "COMPLETED") {

          setPaymentStatus("COMPLETED");
          setTimeout(() => {
            navigate("/")
          }, 1000);
        } else {
          setPaymentStatus("FAILED");
          setTimeout(() => {
            navigate(`${paymentData.backurl}`)
          }, 1000);
        }

      } else {
        // Handle unexpected response
        swal({
          title: "Payment Verification Failed",
          text: "Unable to verify your payment status. Please contact support.",
          icon: "error",
          button: "OK",
        });
      }
    } catch (error) {
      // Handle API errors
      console.error("Error checking payment status:", error);
      swal({
        title: "Error!",
        text: "Unable to verify payment status. Please try again.",
        icon: "error",
        button: "OK",
      });
    }
  };

  useEffect(() => {
    // Call payment status check when component mounts
    if (transactionId && userId) {
      checkPaymentStatus();
    } else {
      swal({
        title: "Invalid Payment Details",
        text: "Missing transaction ID or user ID.",
        icon: "error",
        button: "OK",
      });
      navigate(-1);
    }
  }, [transactionId, userId, navigate]);

  return (
    <div className="payment-success-container">
      {paymentStatus === "COMPLETED" ? (
        <>
          <FaCheckCircle className="payment-success-icon" />
          <h1 className="payment-success-title">Payment Successful!</h1>
          <p className="payment-success-message">
            Thank you for your payment,{" "}
            <strong>{paymentDetails?.username}</strong>.
          </p>
          <p className="payment-success-message">
            Amount: ₹{paymentDetails?.amount}
          </p>
          <button onClick={handleHomeClick} className="payment-success-button">
            Go To My Order
          </button>
        </>
      ) : paymentStatus === "FAILED" ? (
        <>
          <FaTimesCircle className="payment-failed-icon" />
          <h1 className="payment-failed-title">Payment Failed</h1>
          <p className="payment-failed-message">
            We could not process your payment. Please try again.
          </p>
          <button onClick={() => navigate(-1)} className="payment-success-button">
            Retry Payment
          </button>
        </>
      ) : (
        <p>Loading payment status...</p>
      )}
    </div>
  );
};

export default PaymentSuccess;
