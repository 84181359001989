import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import parse from "html-react-parser";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { FaRegEdit } from "react-icons/fa";

function AboutusAdmin() {

  const [eventid, setEventId] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [NewData, setNewData] = useState([]);
  const GetAboutus = async () => {
    try {
      const getdata = await axios.get(
        `https://thekitu.com/api/admin/getAboutus`
      );
      if (getdata.status === 200) {
        setNewData(getdata.data.news);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const DeleteAboutus = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this news item?"
    );

    if (!confirmDelete) {
      return; // Exit if the user cancels the action
    }

    try {
      const res = await axios.delete(
        `https://thekitu.com/api/admin/deleteAboutus/${id}`
      );

      if (res.status === 200) {
        alert(res.data.success);
        GetAboutus(); // Refresh the news list after successful deletion
      }
    } catch (error) {
      console.error(error);
      alert(
        error.response?.data?.error ||
          "An error occurred while deleting the news"
      );
    }
  };

  const [about, setAbout] = useState("");
  const [whyjoin, setWhyJoin] = useState("");
  const [whyjoinimg, setWhyJoinImage] = useState("");
  const [whydonate, setWhyDonate] = useState("");
  const [whydonateimg, setWhyDonateImage] = useState("");

  const [about2, setAbout2] = useState("");
  const [whyjoin2, setWhyJoin2] = useState("");
  const [whyjoinimg2, setWhyJoinImage2] = useState("");
  const [whydonate2, setWhyDonate2] = useState("");
  const [whydonateimg2, setWhyDonateImage2] = useState("");


 
  const AddAboutus = async () => {
    if (!about) {
      return alert("Please enter about kitu");
    }
    if (!whyjoin) {
      return alert("Please enter why join kitu");
    }
    if (!whydonate) {
      return alert("Please enter why donate kitu");
    }
    if (!whyjoinimg) {
      return alert("Please enter why join kitu image");
    }
    if (!whydonateimg) {
      return alert("Please enter why donate kitu image");
    }

    try {
      const formData = new FormData();
      formData.append("aboutkittu", about); // Make sure the name here matches the field in your backend
      formData.append("whyjoinkittu", whyjoin);
      formData.append("whydonatekittu", whydonate);
      formData.append("whyjoinimage", whyjoinimg);
      formData.append("whydonateimage", whydonateimg);

      const config = {
        url: "/addAboutus",
        method: "post",
        baseURL: "https://thekitu.com/api/admin",
        data: formData,
      };

      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        GetAboutus();
        handleClose();
        setAbout("");
        setWhyJoin("");
        setWhyDonate("");
        setWhyJoinImage("");
        setWhyDonateImage("");
      }
    } catch (error) {
      alert(error.response?.data?.error || "An error occurred");
    }
  };

  useEffect(() => {
    GetAboutus();
  }, []);


  const UpdateAboutus = async () => {
    try {
      const formData = new FormData();
      formData.append("aboutkittu", about2); // Make sure the name here matches the field in your backend
      formData.append("whyjoinkittu", whyjoin2);
      formData.append("whydonatekittu", whydonate2);
      formData.append("whyjoinimage", whyjoinimg2);
      formData.append("whydonateimage", whydonateimg2);


      const config = {
        url: `/updateaboutus/${eventid}`,
        method: "put",
        baseURL: "https://thekitu.com/api/admin",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const res = await axios(config);
  
      if (res.status === 200) {
        alert("data updated successfully...");
        GetAboutus();
        handleClose2()
      }
    } catch (error) {
      console.log("error", error);
      alert("something went wrong");
    }
  }

  return (
    <div>
      <div className="d-flex justify-content-between mt-4">
        <div>
          <h3>About Us</h3>
        </div>
        <div>
          {
           NewData?.length > 0 ? (''):(<Button variant="danger" onClick={handleShow}>
            Add
          </Button>) 
          }
       
        </div>
      </div>
      <div className="mt-3">
        <Table bordered>
          <thead className="tablecolor">
            <tr>
              <th>Sl.No</th>
              <th>Date</th>
              <th>About Kittu</th>
              <th>Why Join Kittu</th>
              <th>Why Join Image</th>
              <th>Why Donate Kittu</th>
              <th>Why Donate Image</th>
              {/* <th>Status</th> */}
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {NewData?.map((item, i) => {
              return (
                <tr>
                  <td>{++i}</td>
                  <td>
                    <div style={{ width: "100px" }}>
                      {moment(item?.createdAt)?.format("DD-MM-YYYY")}
                    </div>
                  </td>
                  <td>{item?.aboutkittu}</td>
                  <td>{item?.whyjoinkittu}</td>
                
      <td>
                    <img
                      src={`https://thekitu.com/Aboutus/${item?.whyjoinimage}`}
                      alt="newsimage"
                      style={{ width: "150px", height: "150px" }}
                    />
                  </td>
                  <td>{item?.whydonatekittu} </td>
      
      <td>
                    <img
                      src={`https://thekitu.com/Aboutus/${item?.whydonateimage}`}
                      alt="newsimage"
                      style={{ width: "150px", height: "150px" }}
                    />
                  </td>

                  <td>
                    <MdDelete
                      onClick={() => DeleteAboutus(item?._id)}
                      style={{
                        color: "red",
                        fontSize: "30px",
                        cursor: "pointer",
                      }}
                    />
                    <FaRegEdit
                      style={{
                        color: "red",
                        fontSize: "25px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setEventId(item?._id);
                        setAbout2(item?.aboutkittu);
                        setWhyJoin2(item?.whyjoinkittu);
                        setWhyDonate2(item?.whydonatekittu);
                        handleShow2();
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add About Us </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
          
            <div className="row">
              <Form.Label>
                About Kitu<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="enter about kitu"
                onChange={(e) => setAbout(e.target.value)}
              />
            </div>

            <div className="row">
              <Form.Label>
                Why Join Kitu<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="enter why join kitu"
                onChange={(e) => setWhyJoin(e.target.value)}
              />
            </div>

            <div className="row">
              <div className="col-sm-6">
                <Form.Label>
                  Why Join Image<span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  type="file"
                  onChange={(e) => setWhyJoinImage(e.target.files[0])}
                />
              </div>
            </div>

            <div className="row">
              <Form.Label>
                Why Donate Kitu<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="enter why donate kitu"
                onChange={(e) => setWhyDonate(e.target.value)}
              />
            </div>

            <div className="row">
              <div className="col-sm-6">
                <Form.Label>
                  Why Donate Image<span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  type="file"
                  onChange={(e) => setWhyDonateImage(e.target.files[0])}
                />
              </div>
            </div>

          
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={AddAboutus}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={show2}
        onHide={handleClose2}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Update About Us </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
          
            <div className="row">
              <Form.Label>
                About Kitu<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={about2}
                onChange={(e) => setAbout2(e.target.value)}
              />
            </div>

            <div className="row">
              <Form.Label>
                Why Join Kitu<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={whyjoin2}
                onChange={(e) => setWhyJoin2(e.target.value)}
              />
            </div>

            <div className="row">
              <div className="col-sm-6">
                <Form.Label>
                  Why Join Image<span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  type="file"
                  onChange={(e) => setWhyJoinImage2(e.target.files[0])}
                />
              </div>
            </div>

            <div className="row">
              <Form.Label>
                Why Donate Kitu<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={whydonate2}
                onChange={(e) => setWhyDonate2(e.target.value)}
              />
            </div>

            <div className="row">
              <div className="col-sm-6">
                <Form.Label>
                  Why Donate Image<span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  type="file"
                  onChange={(e) => setWhyDonateImage2(e.target.files[0])}
                />
              </div>
            </div>

          
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button variant="danger" onClick={UpdateAboutus}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
  

    </div>
  );
}

export default AboutusAdmin;
