import React, { useEffect, useState } from "react";
import "../styles/Footer.css";
import Modal from "./Modal";
import { FaArrowRightLong, FaYoutube } from "react-icons/fa6";
import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaTwitterSquare,
} from "react-icons/fa";
import axios from "axios";
import { Link } from "react-router-dom";
const Footer = () => {
  const [showModal, setShowModal] = useState(false);

  const [NewData, setNewData] = useState([]);
  const GetSocialMedia = async () => {
    try {
      const getdata = await axios.get(
        `https://thekitu.com/api/admin/getsocialmedialink`
      );
      if (getdata.status === 200) {
        setNewData(getdata.data.news);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetSocialMedia();
  }, []);
  const iconMap = {
    Facebook: FaFacebookSquare,
    Instagram: FaInstagramSquare,
    Twitter: FaTwitterSquare,
    YouTube: FaYoutube,
  };

  const [address, setAddress] = useState([]);
  const GetAddressDetails = async () => {
    try {
      const getdata = await axios.get(
        `https://thekitu.com/api/admin/getaddressdetails`
      );
      if (getdata.status === 200) {
        setAddress(getdata.data.news[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    GetAddressDetails()
  }, [])

  return (
    <div>
      <div className="footer ">
        <div className="footer-container container">
          <div className="footer-column">
            <h3>KITU </h3>
            <h3 style={{ fontSize: "16px" }}>Karnataka State IT/ITeS Employees Union</h3>
            <h3 className="" style={{ fontWeight: "300", fontSize: "16px" }}>Reg. ALC-Banglore-04/TUA/REGN/CR-17/2017-18</h3>
            <div className="company-address">
              <p>
                {address?.address}
              </p>
              <p>
                <b>Mobile :</b> +91 {address?.phoneno}
              </p>
              <p className="mb-3">
                <b>Email :</b> <Link to="" style={{ textDecoration: "none", }} className="footer-email"> {address?.email}</Link>
              </p>
            </div>
            <div></div>
            <div className="d-flex gap-3 align-items-center justify-content-center justify-content-lg-start">
              <div className="d-flex justify-content-center align-items-center">
                <Link to='/joinus'>
                  <button
                    className="footer-joinus"
                  >
                    Join Us
                  </button>
                </Link>
              </div>
              {/* <div>
                {" "}
                <a href="">
                  <button className="footer-donate">Donate</button>
                </a>
              </div> */}
            </div>
          </div>
          <div className="footer-column">
            <h3>Quick Links</h3>
            <ul>
              <li>
                <Link to="/">
                  <span>
                    <FaArrowRightLong />
                  </span>{" "}
                  Home{" "}
                </Link>
              </li>
              <li>
                <Link to="/about">
                  <span>
                    <FaArrowRightLong />
                  </span>{" "}
                  About
                </Link>
              </li>
              <li>
                <Link to="/recent">
                  {" "}
                  <span>
                    <FaArrowRightLong />
                  </span>{" "}
                  Recent
                </Link>
              </li>
              <li>
                <Link to="/outreach">
                  <span>
                    <FaArrowRightLong />
                  </span>{" "}
                  Outreach
                </Link>
              </li>
              <li>
                <Link to="/study&researchcenter">
                  <span>
                    <FaArrowRightLong />
                  </span>{" "}
                  Study & Research Center
                </Link>
              </li>
              <li>
                <Link to="/gallery">
                  <span>
                    <FaArrowRightLong />
                  </span>{" "}
                  Gallery
                </Link>
              </li>
              <li>
                <Link to="/contact">
                  <span>
                    <FaArrowRightLong />
                  </span>{" "}
                  Contact
                </Link>
              </li>
            </ul>
          </div>

          <div className="footer-column">
            <h3>Social Media Links</h3>
            {/* <div className="social-icons">
              {NewData?.filter((ele)=>ele.media ==="Facebook" )?.map((item)=>{
                return(
                  <a
                  href={item?.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i>
                    <FaFacebookSquare />
                  </i>
                </a>
                )
              })}
              {NewData?.filter((ele)=>ele.media ==="Instagram" )?.map((item)=>{
                return(
                  <a
                  href={item?.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i>
                    <FaInstagramSquare />
                  </i>
                </a>
                )
              })}
              {NewData?.filter((ele)=>ele.media ==="Twitter" )?.map((item)=>{
                return(
                  <a
                  href={item?.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i>
                    <FaTwitterSquare />
                  </i>
                </a>
                )
              })}
              {NewData?.filter((ele)=>ele.media ==="YouTube" )?.map((item)=>{
                return(
                  <a
                  href={item?.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i>
                    <FaYoutube />
                  </i>
                </a>
                )
              })}
             
            </div> */}

            <div className="social-icons">
              {NewData?.map((item) => {
                const IconComponent = iconMap[item.media];
                return (
                  IconComponent && (
                    <Link
                      key={item?.link}
                      to={item?.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i>
                        <IconComponent />
                      </i>
                    </Link>
                  )
                );
              })}
            </div>
            <Link to="/termsandconditions" className="tandc"><div className="ps-3 pt-2">Terms and Conditions</div></Link>
            <Link to="/privacypolicy" className="privacy"><div className="ps-3 pt-2">Privacy Policy</div></Link>
          </div>


        </div>
      </div>
    </div>
  );
};

export default Footer;
