import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import parse from "html-react-parser";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { FaEdit } from "react-icons/fa";

function ContactDetails() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [NewData, setNewData] = useState([]);
  const GetAddressDetails = async () => {
    try {
      const getdata = await axios.get(
        `https://thekitu.com/api/admin/getaddressdetails`
      );
      if (getdata.status === 200) {
        setNewData(getdata.data.news);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [Address, setAddress] = useState("");
  const [MobileNo, setMobileNo] = useState("");
  const [Email, setEmail] = useState("");

  const AddContactDetails = async () => {
    if (!Address) {
      return alert("Enter full address");
    }
    if (!MobileNo) {
      return alert("Enter Mobile no..");
    }
    if (!Email) {
      return alert("Enter Email..");
    }

    try {
      const config = {
        url: "/addaddressdetails",
        method: "post",
        baseURL: "https://thekitu.com/api/admin",
        data: {
          address: Address,
          phoneno: MobileNo,
          email:Email,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        GetAddressDetails();
        handleClose();
        setAddress("")
        setMobileNo("")
        setEmail("")
      }
    } catch (error) {
      alert(error.response?.data?.error || "An error occurred");
    }
  };

  const [AddressItem, setAddressItem] = useState({});


    useEffect(() => {
      if (AddressItem) {
        setAddress(AddressItem?.address || "");
        setMobileNo(AddressItem?.phoneno || "");
        setEmail(AddressItem?.email || "");
      }
    }, [AddressItem]);

  const EditAddressDetails = async () => {
    try {
      const config = {
        url: "/updateaddresssdetails",
        method: "put",
        baseURL: "https://thekitu.com/api/admin",
        data: {
          id: AddressItem?._id,
          address: Address,
          phoneno: MobileNo,
          email: Email,
        },
      };

      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        GetAddressDetails();
        handleClose1();
      }
    } catch (error) {
      alert(error.response?.data?.error || "An error occurred");
    }
  };

  useEffect(() => {
    GetAddressDetails();
  }, []);

  return (
    <div>
      <div className="d-flex justify-content-between mt-4">
        <div>
          <h3>Address Details </h3>
        </div>
        <div>
          {
            NewData?.length > 0 ? '' : (   <Button variant="danger" onClick={handleShow}>
              Add
            </Button>)
          }
       
        </div>
      </div>
      <div className="mt-3">
        <Table bordered>
          <thead className="tablecolor">
            <tr>
              <th>Sl.No</th>
              <th>Address</th>
              <th>Phone No</th>
              <th>Email</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {NewData?.map((item, i) => {
              return (
                <tr>
                  <td>{++i}</td>
                  <td>{item?.address} </td>
                  <td>{item?.phoneno} </td>
                  <td>{item?.email} </td>

                  <td>
                    <FaEdit
                      onClick={() => {
                        handleShow1();
                        setAddressItem(item);
                      }}
                      style={{
                        color: "#BA1D1D",
                        fontSize: "30px",
                        cursor: "pointer",
                      }}
                    />
                  </td>
                </tr>
              );
            })}

           
          </tbody>
        </Table>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Address Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <div className="row">
              <Form.Label>
                Address<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter full address"
                onChange={(e) => setAddress(e.target.value)}
                value={Address}
              />
            </div>
            <div className="row">
              <Form.Label>
                Phone no : <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter phone no"
                onChange={(e) => setMobileNo(e.target.value)}
                value={MobileNo}
                maxLength={10}
              />
            </div>
            <div className="row">
              <Form.Label>
                Email : <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
                value={Email}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={AddContactDetails}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={show1}
        onHide={handleClose1}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Address Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="">
            <div className="row">
              <Form.Label>
                Address<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter full address"
                onChange={(e) => setAddress(e.target.value)}
                value={Address}
              />
            </div>
            <div className="row">
              <Form.Label>
                Phone no : <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter phone no"
                onChange={(e) => setMobileNo(e.target.value)}
                value={MobileNo}
                maxLength={10}
              />
            </div>
            <div className="row">
              <Form.Label>
                Email : <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
                value={Email}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="danger" onClick={EditAddressDetails}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ContactDetails;
