import React, { useEffect, useState } from "react";
import "../styles/Mostpopular.css";
import Carousel from "react-grid-carousel";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import parse from "html-react-parser";

const MostPopular = () => {
  const navigate = new useNavigate();
  const [NewData, setNewData] = useState([]);
  const GetMostPopuler = async () => {
    try {
      const getdata = await axios.get(
        `https://thekitu.com/api/admin/getmostpopuler`
      );
      if (getdata.status === 200) {
        setNewData(getdata.data.news);
      }
    } catch (error) {
      console.log(error);
    }
  };


  const [CommunityOutreach, setCommunityOutreach] = useState([]);
  const GetCommunityOutreach = async () => {
    try {
      const getdata = await axios.get(
        `https://thekitu.com/api/admin/getcommunityoutreach`
      );
      if (getdata.status === 200) {
        setCommunityOutreach(getdata.data.news);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [InNews, setInNews] = useState([]);
  const GetInlinks = async () => {
    try {
      const getdata = await axios.get(
        `https://thekitu.com/api/admin/getInlinks`
      );
      if (getdata.status === 200) {
        setInNews(getdata.data.news);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetMostPopuler();
    GetCommunityOutreach();
    GetInlinks();
  }, []);


  const renderCards = (NewData) => {
    return NewData.map((item, index) => (
      <div className="col-md-3" key={index}>
        <div className="card-container card-imagition1">
          <div className="card my-card">
            <img src={`https://thekitu.com/News/${item?.image}`} alt="Image1" className="card-image1" />
            <div className="card-content">
              <Link to={`/mostpopularnews/${item._id}`} style={{ WebkitLineClamp: "3", lineClamp: "initial" }} className="lineclamp mb-2">
                <h5>{parse(`<div>${item?.title}</div>`)}</h5>
              </Link>
              <p className="lineclamp" style={{ WebkitLineClamp: "3", lineClamp: "initial" }}>{parse(`<div>${item?.description}</div>`)}</p>
            </div>
          </div>
        </div>
      </div>
    ));
  };

  const renderCards2 = (NewData) => {
    return NewData.map((item, index) => (
      <div className="col-md-3" key={index}>
        <div className="card-container card-imagition1">
          <div className="card my-card">
            <img src={`https://thekitu.com/News/${item?.image}`} alt="Image1" className="card-image1" />
            <div className="card-content">
              <Link to={`/outreach/${item?._id}`} className="lineclamp mb-2">
                <h5>{parse(`<div>${item?.title}</div>`)}</h5>
              </Link>
              <p className="lineclamp" style={{ WebkitLineClamp: "3", lineClamp: "initial" }}>{parse(`<div>${item?.description}</div>`)}</p>
            </div>
          </div>
        </div>
      </div>
    ));
  };


  return (
    <div>
      <div className="">
        <div className="row">
          <div
            className="col-sm-9 p-5 most-populer"
            style={{ position: "relative", marginTop: "-15px" }}
          >
            <div className="lastestnews-event" style={{ marginLeft: "0px" }}>
              <h3>Most Popular</h3>
            </div>



            <div className="row">
              {renderCards(NewData.slice(0, 4))}
            </div>




            <div className="lastestnews-event" style={{ marginLeft: "0px" }}>
              <h3>Outreach</h3>
            </div>
            <div className="row ">
              {renderCards2(CommunityOutreach.slice(0, 4))}
            </div>

          </div>





          <div className="col-md-3 " >
            <div className="">
              <div className="lastestnews-event" style={{ marginLeft: "0px" }}>
                <h3>In News</h3>
              </div>
              <div className="in-news">
                {InNews?.slice(0, 8)?.map((item, i) => {
                  return (
                    <div className="row mt-2" key={i}>
                      <div className="col-12 d-flex  flex-md-row align-items-start">
                        {/* Title */}
                        <div className="col-md-8 text-truncation" style={{ wordWrap: "break-word", lineHeight: "1.2" }}>
                          <Link
                            to={item?.links}
                            style={{ textDecoration: "none", color: "black", fontWeight: "500", fontSize: "13px" }}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-truncation"
                          >
                            {item?.title}
                          </Link>
                        </div>

                        {/* Image */}
                        <div className="col-md-4 d-flex justify-content-center mt-2 mt-md-0">
                          <Link to={item?.links} target="_blank" rel="noopener noreferrer">
                            <img
                              style={{
                                width: "100px",
                                height: "100px",
                                borderRadius: "7px",
                                objectFit: "cover",
                                objectPosition: "center",
                              }}
                              src={`https://thekitu.com/InLinks/${item?.image}`}
                              alt=""
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MostPopular;
