import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { FaFileInvoice } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx"; // Import xlsx library

function MembershipList() {

  const [NewData, setNewData] = useState([]);
  const getMembership = async () => {
    try {
      const getdata = await axios.get(
        `https://thekitu.com/api/user/getmembership`
      );
      if (getdata.status === 200) {
        setNewData(getdata.data.membership);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const Deletemembership = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this news item?"
    );

    if (!confirmDelete) {
      return; // Exit if the user cancels the action
    }

    try {
      const res = await axios.delete(
        `https://thekitu.com/api/user/deletemembership/${id}`
      );

      if (res.status === 200) {
        alert(res.data.success);
        getMembership(); // Refresh the news list after successful deletion
      }
    } catch (error) {
      console.error(error);
      alert(
        error.response?.data?.error ||
        "An error occurred while deleting the news"
      );
    }
  };

  const downloadExcel = () => {
    // Filter data to include only "Donation" type
    const filteredData = NewData.filter((item) => item.formtype === "Membership");

    // Prepare data for Excel
    const dataForExcel = filteredData.map((item, i) => ({
      "Sl No.": i + 1,
      "Membership Id": item?._id,
      Name: item?.name,
      "Phone No": item?.mobileno,
      Gender: item?.gender,
      Company: item?.company,
      "Pay Type": item?.formtype,
      "Pay Id": item?.payid,
      Designation: item?.designation,
      "Residential Address": item?.address,
      "Membership Amount": `${item?.amount}/-`,
    }));

    // Create a new worksheet and workbook
    const worksheet = XLSX.utils.json_to_sheet(dataForExcel);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Membership List");

    // Write and download Excel file
    XLSX.writeFile(workbook, "MembershipList.xlsx");
  };

  useEffect(() => {
    getMembership();
  }, []);

  const navigate = useNavigate()

  const handleReceipt = (item) => {
    navigate(`/receipt/${item?._id}`, { state: { item } })
  }

  return (
    <div>
      <div className="d-flex justify-content-between mt-4">
        <div className="d-flex justify-content-between align-items-center w-100 gap-5">
          <h3>Membership List</h3>
          <Button onClick={downloadExcel} style={{ backgroundColor: "#BA1D1D" }}>
            Download Excel
          </Button>
        </div>
        <div></div>
      </div>
      <div className="mt-3">
        <Table bordered>
          <thead className="tablecolor">
            <tr>
              <th>Sl no.</th>
              <th>Membership Id</th>
              <th>Name</th>
              <th>Phone No</th>
              <th>Email</th>
              <th>Gender</th>
              <th>Company</th>
              <th>Pay Type</th>
              <th>Pay Id</th>
              <th>Designation</th>
              <th>Residential Address</th>
              <th>Donation Amount</th>
              <th>Receipt</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {NewData.filter((item) => item.formtype === "Membership")?.map((item, i) => {
              return (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{item?._id}</td>
                  <td>{item?.name}</td>
                  <td>{item?.mobileno}</td>
                  <td>{item?.email}</td>
                  <td>{item?.gender}</td>
                  <td>{item?.company}</td>
                  <td>{item?.formtype}</td>
                  <td>{item?.payid}</td>
                  <td>{item?.designation}</td>
                  <td>{item?.address}</td>
                  <td>{item?.amount}/-</td>
                  <td >
                    <FaFileInvoice
                      onClick={() => handleReceipt(item)}
                      style={{
                        color: "red",
                        fontSize: "30px",
                        cursor: "pointer",
                      }}
                    />
                  </td>
                  <td>
                    <MdDelete
                      className="mt-4"
                      onClick={() => Deletemembership(item?._id)}
                      style={{
                        color: "red",
                        fontSize: "30px",
                        cursor: "pointer",
                      }}
                    />

                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default MembershipList;
