import React, { useEffect, useState } from "react";
import "../styles/Latestnews.css";
import axios from "axios";
import parse from "html-react-parser";
import { Link, useNavigate } from "react-router-dom";


const LatestNews = () => {

  const navigate = new useNavigate()

  const [NewData, setNewData] = useState([]);
  const GetNews = async () => {
    try {
      const getdata = await axios.get(
        `https://thekitu.com/api/admin/getnewsandevent`
      );
      if (getdata.status === 200) {
        setNewData(getdata.data.news);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [Campaign, setCampaign] = useState([]);
  const GetCampign = async () => {
    try {
      const getdata = await axios.get(
        `https://thekitu.com/api/admin/getCampaign`
      );
      if (getdata.status === 200) {
        setCampaign(getdata.data.news);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetNews();
    GetCampign();
  }, [])


  const renderCards = (NewData) => {
    return NewData.map((item, index) => (
      <div className="col-md-4" key={index}>
        <div className="card-container card-imagition">
          <div className="card my-card">
            <img src={`https://thekitu.com/News/${item?.image}`} alt="Image1" className="card-image" />
            <div className="card-content">
              <Link to={`/recent/${item?._id}`}  style={{ WebkitLineClamp: "3", lineClamp: "initial" }} className="lineclamp mb-2">
                <h5>{parse(`<div>${item?.title}</div>`)}</h5>
              </Link>
              <p className="lineclamp" style={{ WebkitLineClamp: "3", lineClamp: "initial" }}>{parse(`<div>${item?.description}</div>`)}</p>
            </div>
          </div>
        </div>
      </div>
    ));
  };


  const renderSecondRow = (NewData) => {
    return NewData.map((item, index) => (
      <div className="col-md-3" key={index}>
        <div className="card-container card-imagition1">
          <div className="card my-card">
            <img src={`https://thekitu.com/News/${item?.image}`} alt="Image1" className="card-image1" />
            <div className="card-content">
              <Link href={`/campaign/${item._id}`}  className="lineclamp mb-2">
                <h5>{parse(`<div>${item?.title}</div>`)}</h5>
              </Link>
              <p className="lineclamp" style={{ WebkitLineClamp: "3", lineClamp: "initial" }}>{parse(`<div>${item?.description}</div>`)}</p>
            </div>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-9 p-5  " style={{ position: "relative" }}>
          <div className="lastestnews-event" style={{ marginLeft: "0px" }}>
            <h3>Recent</h3>

          </div>
          <div className="row">
            {renderCards(NewData.slice(0, 3))}

          </div>

          <div className="row">
            {renderSecondRow(NewData.slice(3, 7))}

          </div>
        </div>

        <div className="col-md-3" style={{ marginBottom: "60px" }}>
          <div className="lastestnews-updates ">
            <h3>Campaign</h3>
          </div>
          <div className="mt-3  d-flex justify-content-start flex-column align-items-start">
            {Campaign?.map((item, index) => {
              if (index <= 2) {
                return (
                  <div style={{ cursor: "pointer" }} className="campaign-img mt-2" onClick={() => navigate(`/campaign/${item._id}`)}>
                    <img src={`https://thekitu.com/Campaign/${item?.image}`} alt="" />
                  </div>
                )
              }

            })}

            {/* <div className="campaign-img mt-2">
              <img src="/Assets/image2.jpg" alt="" />
            </div>
            <div className="campaign-img mt-2">
              <img src="/Assets/image2.jpg" alt="" />
            </div>
            <div className="campaign-img mt-2">
              <img src="/Assets/image2.jpg" alt="" />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LatestNews;
