import React, { useEffect, useState } from "react";
import "../styles/Modal.css";
import axios from "axios";
import { Button } from "react-bootstrap";

const JoinUs = () => {
  const [Name, setName] = useState("");
  const [MobileNo, setMobileNo] = useState("");
  const [Gender, setGender] = useState("");
  const [Company, setCompany] = useState("");
  const [Designation, setDesignation] = useState("");
  const [Address, setAddress] = useState("");
  const [Email, setEmail] = useState("");
  // Function to generate a unique user ID
  function generateUserID() {
    const prefix = "USER";
    const timestamp = Date.now(); // Current timestamp
    const randomNum = Math.floor(1000 + Math.random() * 9000); // 4-digit random number
    return `${prefix}-${timestamp}-${randomNum}`;
  }

  // Function to generate a unique order ID
  function generateOrderID() {
    const prefix = "ORDER";
    const timestamp = Date.now(); // Current timestamp
    const randomNum = Math.floor(1000 + Math.random() * 9000); // 4-digit random number
    return `${prefix}-${timestamp}-${randomNum}`;
  }

  function generatePayId() {
    const prefix = "KITUP";
    const timestamp = Date.now(); // Current timestamp
    const randomNum = Math.floor(1000 + Math.random() * 9000); // 4-digit random number
    return `${prefix}-${timestamp}-${randomNum}`;
  }
  // Example usage
  const payid = generatePayId()
  const userID = generateUserID();
  const orderID = generateOrderID();

  function validateIndianMobileNumber(mobileNumber) {
    // Regular expression for a valid Indian mobile number
    const indianMobileRegex = /^[6-9]\d{9}$/;

    // Test the input against the regex
    if (indianMobileRegex.test(mobileNumber)) {
      return true; // Valid number
    } else {
      return false; // Invalid number
    }
  }

  const Membership = async () => {
    if (!Name) {
      return alert("Enter Name")
    }
    if (!validateIndianMobileNumber(MobileNo)) {
      return alert("Invalid Mobile No")
    }
    if (!MobileNo) {
      return alert("Enter Mobile No")
    }
    if (!Gender) {
      return alert("Select gender")
    }
    if (!Company) {
      return alert("Enter Company Name")
    }
    if (!Designation) {
      return alert("Enter Designation")
    }
    if (!Address) {
      return alert("Enter Address")
    }
    try {

      const config = {
        method: "post",
        url: "/addmembership",
        baseURL: "https://thekitu.com/api/user", // Keep baseURL here
        headers: { "Content-Type": "application/json" }, // Corrected header
        data: {
          name: Name,
          mobileno: MobileNo,
          gender: Gender,
          email: Email,
          company: Company,
          designation: Designation,
          address: Address,
          amount: 600,
          formtype: "Membership",
          payid: payid
        },
      };
      const config1 = {
        method: "post",
        url: "/addpaymentphonepay",
        baseURL: "https://thekitu.com/api/user", // Keep baseURL here
        headers: { "Content-Type": "application/json" }, // Corrected header
        data: {
          userId: userID,
          username: Name,
          Mobile: MobileNo,
          orderId: orderID,
          amount: 600,
          backurl: "/joinus",
          config: JSON.stringify(config)
        }
      }

      let res = await axios(config1);
      if (res.status === 200) {
        window.location.assign(res.data.url?.url)

      }
    } catch (error) {
      alert(error.response?.data?.error || "An error occurred");
    }
  };

  useEffect(()=>{window.scrollTo(0, 0)},[])


  return (
    <div className="d-flex justify-content-center align-items-center" >
      <div
        className="modal-content"
        onClick={(e) => e.stopPropagation()}
        style={{ width: "70%", backgroundColor: "white" }}
      >
        <div className="modal-header text-center">
          <h4>Membership Form</h4>

        </div>
        <div className="modal-body">
          <form>
            <label>
              Name{" "}
              <i
                class="fas fa-asterisk"
                style={{ color: "red", fontSize: "10px" }}
              />
            </label>
            <input
              onChange={(e) => setName(e.target.value)}
              value={Name}
              placeholder="Enter your name.."
              type="text"
              required
            />

            <label>
              Contact Number{" "}
              <i
                class="fas fa-asterisk"
                style={{ color: "red", fontSize: "10px" }}
              />
            </label>
            <input
              onChange={(e) => setMobileNo(e.target.value)}
              type="tel"
              maxLength={10}
              placeholder="Enter your number"
              required
            />

            <label>
              Email
              <i
                class="fas fa-asterisk"
                style={{ color: "red", fontSize: "10px" }}
              />
            </label>
            <input
              onChange={(e) => setEmail(e.target.value)}
              type="email"

              placeholder="Enter your email"
              required
            />

            <label>
              Gender{" "}
              <i
                class="fas fa-asterisk"
                style={{ color: "red", fontSize: "10px" }}
              />
            </label>
            <select onChange={(e) => setGender(e.target.value)} required>
              <option value="">Select Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>

            <label>
              Company{" "}
              <i
                class="fas fa-asterisk"
                style={{ color: "red", fontSize: "10px" }}
              />
            </label>
            <input
              onChange={(e) => setCompany(e.target.value)}
              value={Company}
              type="text"
              required
              placeholder="Enter your company name" />

            <label>
              Designation{" "}
              <i
                class="fas fa-asterisk"
                style={{ color: "red", fontSize: "10px" }}
              />
            </label>
            <input
              onChange={(e) => setDesignation(e.target.value)}
              type="text"
              required
              placeholder="Enter your designation" />

            <label>
              Residential Address{" "}
              <i
                class="fas fa-asterisk"
                style={{ color: "red", fontSize: "10px" }}
              />
            </label>
            <textarea
              onChange={(e) => setAddress(e.target.value)}
              required
              placeholder="Enter your full address"></textarea>
            <span style={{
              fontSize: "13px", color: "#ba1d1d"
            }}>
              Subscription Fee Rs. 100, General Fund Rs. 500
            </span>
            <Button variant="danger" onClick={Membership}>Submit</Button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default JoinUs