import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Breadcrumb from 'react-bootstrap/Breadcrumb';

function CommunityOutReach() {

  const navigate = new useNavigate();

  const [NewData, setNewData] = useState([]);
  const GetCommunityOutreach = async () => {
    try {
      const getdata = await axios.get(
        `https://thekitu.com/api/admin/getcommunityoutreach`
      );
      if (getdata.status === 200) {
        setNewData(getdata.data.news);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetCommunityOutreach();
  }, []);
  console.log("NewData", NewData);

  useEffect(()=>{window.scrollTo(0,0)},[])

  return (
    <div>
      <div className="gallery">
        {" "}
        <h1 style={{ borderBottom: "3px solid white" }}>Outreach</h1>

        <Breadcrumb className="myBreadcrumb">
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item href="/outreach">
            Outreach
          </Breadcrumb.Item>

        </Breadcrumb>
      </div>


      <div className="container">
        <section>
          <div className="row">
            {NewData?.map((item, i) => {
              return (
                <div className="col-md-4">
                  <div className="event-card">
                    <div className="all-event">
                      <div className="event-card-content">
                        <h5>{item?.title?.slice(0, 40)}</h5>
                      </div>
                      <div className="event-card-content-hover">
                        <h5>{item?.title?.slice(0, 40)}</h5>

                        <p dangerouslySetInnerHTML={{ __html: item?.description?.length > 150 ? item?.description.slice(0, 150) + '...' : item?.description }} />



                        <Link to={`/outreach/${item?._id}`}>
                          <button className="event-butt">Know More</button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}

          </div>
        </section>
      </div>
    </div>
  );
}

export default CommunityOutReach;
