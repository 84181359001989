import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaTwitterSquare,
} from "react-icons/fa";
import { FaWhatsapp, FaXTwitter, FaYoutube } from "react-icons/fa6";
import { Link } from "react-router-dom";

const SocialMediaLink = () => {

  const [NewData, setNewData] = useState([]);
  const GetSocialMedia = async () => {
    try {
      const getdata = await axios.get(
        `https://thekitu.com/api/admin/getsocialmedialink`
      );
      if (getdata.status === 200) {
        setNewData(getdata.data.news);
      }
    } catch (error) {
      console.log(error);
    }
  };


  const phoneNumber = 7019781243;
  const whatsappMessage = encodeURIComponent(
    "Hello, I am interested in your services."
  );

  const handleClick = () => {
    window.open(
      `https://wa.me/${phoneNumber}?text=${whatsappMessage}`,
      "_blank"
    );
  };

  useEffect(() => {
    GetSocialMedia();
  }, []);

  return (
    <div className="social-media-icons d-flex flex-column gap-4 ">
      <Link
        to={`${NewData[2]?.link}`}
        target="_blank"
        rel="noopener noreferrer"
        className=""
        style={{
          color: "#BA1D1D"
        }}
      >
        <i>
          <FaFacebookSquare className="" />
        </i>
      </Link>
      <Link
        to={`${NewData[1]?.link}`}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          color: "#BA1D1D"
        }}
      >
        <i>
          <FaInstagramSquare />
        </i>
      </Link>
      <Link
        to={`${NewData[0]?.link}`}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          color: "#BA1D1D"
        }}
      >
        <i>
          <FaXTwitter />
        </i>
      </Link>
      <Link
        to={`${NewData[3]?.link}`}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          color: "#BA1D1D"
        }}
      >
        <i>
          <FaYoutube />
        </i>
      </Link>
      <Link
        onClick={handleClick}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          color: "#BA1D1D"
        }}
      >
        <i>
          <FaWhatsapp />
        </i>
      </Link>
    </div>
  );
};

export default SocialMediaLink;
