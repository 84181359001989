import React, { useEffect, useState } from "react";
import Carousel from "react-grid-carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/Home.css";
import LatestNews from "./LatestNews";
import MostPopular from "./MostPopular";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import parse from "html-react-parser";




const Home = () => {
  const navigate = new useNavigate();
  const [NewData, setNewData] = useState([]);
  const GetStudyResearchCenter = async () => {
    try {
      const getdata = await axios.get(
        `https://thekitu.com/api/admin/getstudyresearchcenter`
      );
      if (getdata.status === 200) {
        setNewData(getdata.data.news);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [GalleryImg, setGalleryImg] = useState([]);
  const GetGalleryImages = async () => {
    try {
      const getdata = await axios.get(
        `https://thekitu.com/api/admin/getGalleryImages`
      );
      if (getdata.status === 200) {
        setGalleryImg(getdata.data.news);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetStudyResearchCenter();
    GetGalleryImages();
  }, []);

  const renderCards = (NewData) => {
    return NewData.map((item, index) => (
      <div className="col-md-3" key={index}>
        <div className="card-container card-imagition1">
          <div className="card my-card">
            <img
              src={`https://thekitu.com/News/${item?.image}`}
              alt="Image1"
              className="card-image1"
            />
            <div className="card-content">
              <Link to={`/study&research/${item._id}`} style={{ WebkitLineClamp: "3", lineClamp: "initial" }} className="lineclamp mb-2">
                <h5>{parse(`<div>${item?.title}</div>`)}</h5>
              </Link>

              <p className="lineclamp" style={{ WebkitLineClamp: "3", lineClamp: "initial" }}>{parse(`<div>${item?.description}</div>`)}</p>
            </div>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div style={{ padding: "0 12px" }}>
      <section id="section2">
        <LatestNews />
      </section>

      <section id="section3">
        <MostPopular />
      </section>

      <section id="section4">
        <div className="row">
          <div
            className="col-md-9 p-5 "
            style={{ position: "relative", marginTop: "-17px" }}
          >
            <div className="lastestnews-event" style={{ marginLeft: "0px" }}>
              <h3>Study & Research Center</h3>
            </div>

            <div className="row">{renderCards(NewData.slice(0, 4))}</div>


          </div>

          <div className="col-md-3" >
            <div className="lastestnews-event" style={{ marginLeft: "0px" }}>
              <h3>Gallery</h3>
            </div>

            <div className="row justify-content-center bbbb mt-3 ">
              <Carousel>
                {GalleryImg?.map((item) => {
                  return (
                    <Carousel.Item>
                      <img
                        className="d-block w-100 eventimage galleryimg"
                        src={`https://thekitu.com/Gallery/${item?.image}`}
                        alt="Second slide"
                      />
                    </Carousel.Item>
                  );
                })}

              </Carousel>
            </div>


            <div className="mt-5 d-flex justify-content-center mb-5">
              <Button variant="danger" onClick={() => navigate("/gallery")}>
                View More
              </Button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
