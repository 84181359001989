import React, { useEffect, useState } from 'react'
import "../styles/Detailsnews.css";
import { useLocation, useParams } from "react-router-dom";
import parse from "html-react-parser";
import axios from 'axios';
function CommunityOutreachDetails() {
  const location = useLocation();
  const [item, setItem] = useState([]);
  const { id } = useParams();

  const getNews = async () => {
    try {
      const res = await axios.get(`https://thekitu.com/api/admin/getcommunityoutreach/${id}`);
      setItem(res?.data?.news);
    } catch (error) {

    }
  }

  useEffect(() => { getNews() }, [])

  useEffect(()=>{window.scrollTo(0,0)},[])
  return (
    <div>
      <div className="details-news">
        <h1>Community OutReach Details</h1>
      </div>
      <div className="container">
        <div className="details-news-img">
          <img
            src={`https://thekitu.com/CommunityOutreach/${item?.image}`}
            alt="images"
          />
          <div className="details-news-content">
            <h3>{item?.title} </h3>

            <p>
              <p>{parse(`<div>${item?.description}</div>`)}</p>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CommunityOutreachDetails