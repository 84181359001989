import React, { useEffect, useState } from "react";
import "../styles/Contact.css";
import axios from "axios";
import { Button } from "react-bootstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from "react-router-dom";
const Contact = () => {
  const [Name, setName] = useState("");
  const [Mobile, setMobile] = useState("");
  const [Email, setEmail] = useState("");
  const [Message, setMessage] = useState("");
  function validateIndianMobileNumber(mobileNumber) {

    const indianMobileRegex = /^[6-9]\d{9}$/;

    // Test the input against the regex
    if (indianMobileRegex.test(mobileNumber)) {
      return true; // Valid number
    } else {
      return false; // Invalid number
    }
  }

  function validateEmail(email) {
    // Regular expression for a valid email address
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // Test the input against the regex
    if (emailRegex.test(email)) {
      return true; // Valid email
    } else {
      return false; // Invalid email
    }
  }

  const AddCantact = async () => {
    if (!Name) {
      return alert("Please enater name");
    }
    if (!validateIndianMobileNumber(Mobile)) {
      return alert("Invalid mobile number");
    }
    if (!Mobile) {
      return alert("Please enter mobile no");
    }
    if (!Email) {
      return alert("Please enter a email");
    }
    if (!validateEmail(Email)) {
      return alert("Please enter valid email id");
    }
    if (!Message) {
      return alert("Please enter a Message");
    }

    try {

      const config = {
        url: "/addContactus",
        method: "post",
        baseURL: "https://thekitu.com/api/admin",
        data: {
          name: Name,
          mobile: Mobile,
          email: Email,
          message: Message,
        },
      };

      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        setName("")
        setMobile("")
        setEmail("")
        setMessage("")
      }
    } catch (error) {
      alert(error.response?.data?.error || "An error occurred");
    }
  };

  const [NewData, setNewData] = useState([]);
  const GetAddressDetails = async () => {
    try {
      const getdata = await axios.get(
        `https://thekitu.com/api/admin/getaddressdetails`
      );
      if (getdata.status === 200) {
        setNewData(getdata.data.news[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    GetAddressDetails()
  }, [])
 
  useEffect(() => {
   window.scrollTo(0,0)
  }, []);


  return (
    <div className="contact-us">
      <div className="gallery">
        <h1 style={{ borderBottom: "3px solid white" }}>Contact Us</h1>
        <Breadcrumb className="myBreadcrumb">
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item href="/contact">
            Contact Us
          </Breadcrumb.Item>

        </Breadcrumb>
      </div>
      <div className="container">
        <div className="row ">
          <div className="col-md-6">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="company-address">
                  <h3>KITU Headquarters</h3>
                  <p>
                    {NewData?.address}
                  </p>
                  <p>
                    <b>Mobile :</b> +91 {NewData?.phoneno}
                  </p>
                  <p>
                    <b>Email :</b> <Link to=""> {NewData?.email}</Link>
                  </p>
                  <div className="map" 
                  style={{ position: "relative", textAlign: "right", width:"100%", height:"400px" }}>
                    <div className="gmap_canvas" style={{ overflow: "hidden", background: "none!important", }}>
                      <iframe
                        className="gmap_iframe"
                        style={{ border: "none" }}
                        scrolling="no"
                        marginHeight="0"
                        marginWidth="0"
                        src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=kituhq&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                        title="Google Map"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 ">
            <div className="message-us">
              <div className="row justify-content-center">
                <div className="col-md-10">
                  <form className="-us">
                    <div className="form-group">
                      <label htmlFor="name">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Enter your name"
                        onChange={(e) => setName(e.target.value)}
                        value={Name}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="mobile">Mobile</label>
                      <input
                        type="tel"
                        className="form-control"
                        id="mobile"
                        placeholder="Enter your mobile number"
                        onChange={(e) => setMobile(e.target.value)}
                        value={Mobile}
                        maxLength={10}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">E-mail</label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Enter your email"
                        onChange={(e) => setEmail(e.target.value)}
                        value={Email}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="message">Message</label>
                      <textarea
                        className="form-control"
                        id="message"
                        rows="3"
                        placeholder="Enter your message"
                        onChange={(e) => setMessage(e.target.value)}
                        value={Message}
                      ></textarea>
                    </div>
                    <div className="d-flex justify-content-center">
                      <Button onClick={AddCantact} className="butt">
                        Send
                      </Button>
                    </div>

                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
