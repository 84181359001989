import React, { useState } from "react";
import "../styles/Navbar.css";
import { Link } from "react-router-dom";
import Modal from "./Modal";
import Lottie from "lottie-react";
import donate from "./../assets/RP2y3LxJAa.json";
import ModelDonet from "./ModelDonet";
const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  const [showModal, setShowModal] = useState(false);
  const [donet, setdonet] = useState(false);

  return (
    <>
      <nav className="navbar navbar-1 ">
        <div className="logotransform">
          <div className="navbar-logo pt-3 pb-3">
            <span>KARNATAKA STATE IT/ITeS EMPLOYEES UNION</span>
          </div>
        </div>

        <ul className={`navbar-menu ${isMobileMenuOpen ? "open" : ""}`}>
          <li>
            <Link to="/" style={{ color: "white" }}>
              HOME
            </Link>
          </li>
          <li>
            <Link to="/about" style={{ color: "white" }}>ABOUT US</Link>
          </li>
          <li>
            <div className="dropdown">
              <button className="action-btn dropdown-toggle  " type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ background: 'none', border: 'none', outline: 'none', fontSize: '16px', cursor: 'pointer', color: "white", fontWeight: "600" }} >
                ACTION
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton"> <Link className="dropdown-item mt-1" to="/recent">RECENT</Link> <Link className="dropdown-item mt-1" to="/outreach">OUTREACH</Link> <Link className="dropdown-item mt-1" to="/study&researchcenter">STUDY & RESEARCH CENTER</Link>
                <Link className="dropdown-item" to="/campaign">CAMPAIGN</Link> </div>
            </div>
          </li>

          <li>
            <Link to="/contact" style={{ color: "white" }}>CONTACT US</Link>
          </li>
          {/* <div className="nav-button1">
            <Modal show={showModal} onClose={() => setShowModal(false)} />
            <ModelDonet show={donet} onClose={() => setdonet(false)} />

            <Link to='/joinus' > <button className="joinus-btn"
              style={{
                height: "40px",
                width: "100px",
                background: "rgb(255, 255, 255)",
                color: "rgb(217 8 18)",
                border: "none",
                borderRadius: " 5px",
                // margin: "15px",
                fontWeight: "bold",
              }}
            >Join Us</button></Link>
            <Link to='/donateus'>
              <button style={{
                height: "40px",
                width: "100px",
                background: "rgb(255, 255, 255)",
                color: "rgb(217 8 18)",
                border: "none",
                borderRadius: " 5px",
                // margin: "15px",
                fontWeight: "bold"
              }} >
                Donate
              </button>
            </Link>
          </div> */}
        </ul>



        <div className="http://localhost:3000/mostpopularnews/The%203rd%20General%20Conference%20of%20Karnataka%20State%20IT%2FITeS%20Employees%20Union%20concluded%20with%20a%20determination%20to%20develop%20stronger%20working%20class%20movement%20in%20the%20IT%2FITeS%20(%26%20BPM)%20indust/679a232e2d5daadf67cc712f">
          <Modal show={showModal} onClose={() => setShowModal(false)} />
          <ModelDonet show={donet} onClose={() => setdonet(false)} />

          <Link to='/joinus' className="pt-5"> <button className="joinus-btn"
            style={{
              height: "40px",
              width: "100px",
              background: "rgb(255, 255, 255)",
              color: "rgb(217 8 18)",
              border: "none",
              borderRadius: " 5px",
              margin: "15px",
              fontWeight: "bold",
            }}
          >Join Us</button></Link>
          <Link to='/donateus'>
            <button style={{
              height: "40px",
              width: "100px",
              background: "rgb(255, 255, 255)",
              color: "rgb(217 8 18)",
              border: "none",
              borderRadius: " 5px",
              margin: "15px",
              fontWeight: "bold"
            }} >
              Donate
            </button>
          </Link>
        </div>

        {/* <div className="navbar-icons">
                    <i className="fas fa-user"></i>
                    <i className="fas fa-search"></i>
                </div> */}
        <div className="navbar-toggle mt-3" onClick={toggleMobileMenu}>
          <i className={`fas ${isMobileMenuOpen ? "fa-times" : "fa-bars"}`}></i>
        </div>
      </nav >
      {/* <div className={`offcanvas ${isMobileMenuOpen ? 'open' : ''}`}>
                <ul className="offcanvas-menu">
                    <li><a href="#home" onClick={toggleMobileMenu}>HOME</a></li>
                    <li><a href="#about" onClick={toggleMobileMenu}>ABOUT US</a></li>
                    <li><a href="#category" onClick={toggleMobileMenu}>EVENT</a></li>
                    <li><a href="#blog" onClick={toggleMobileMenu}>GALLERY</a></li>
                    <li><a href="#contact" onClick={toggleMobileMenu}>CONTACT US</a></li>
                </ul>
            </div> */}
    </>
  );
};

export default Navbar;





