import "../styles/Detailsnews.css";
import parse from "html-react-parser";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Breadcrumb from 'react-bootstrap/Breadcrumb';

const CampaignPage = () => {
  const navigate = new useNavigate()

  const [Campaign, setCampaign] = useState([]);
  const GetCampign = async () => {
    try {
      const getdata = await axios.get(
        `https://thekitu.com/api/admin/getCampaign`
      );
      if (getdata.status === 200) {
        setCampaign(getdata.data.news);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetCampign();
  }, [])
  return (
    <div>
      <div className="gallery">
        {" "}
        <h1 style={{ borderBottom: "3px solid white" }}>Campaign</h1>
        <Breadcrumb className="myBreadcrumb">
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item href="/studyandresearchcenter">
            Campaign
          </Breadcrumb.Item>

        </Breadcrumb>
      </div>
      <div className="container">
        <section>
          <div className="row">
            {Campaign?.map((item, i) => {
              return (
                <div className="col-md-4">
                  <div className="event-card">
                    <div className="all-event">
                      <div className="event-card-content">
                        <h5>{item?.title?.slice(0, 40)}</h5>
                      </div>
                      <div className="event-card-content-hover">
                        <h5>{item?.title?.slice(0, 40)}</h5>
                        <p
                          dangerouslySetInnerHTML={{
                            __html:
                              item?.description?.length > 150
                                ? item?.description.slice(0, 150) + "..."
                                : item?.description,
                          }}
                        />
                        <Link to={`/campaign/${item._id}`}>
                          <button className="event-butt">Know More</button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </section>
      </div>
    </div>
  )
}

export default CampaignPage